


.bulk-merge-container .bulk-merg-container-person {
    border: 1px solid #aaaaaa;    
    padding: 1rem;
    border-radius: 1rem;
    position: relative;
    height: 150px;
    
}

.bulk-merge-container h3.bulk-merg-container-header {
    margin: -1rem -1rem 1rem -1rem !important;
    padding: 1rem;
    border-radius: 1rem 1rem 0 0;
}
.bulk-merge-container .BulkMergerContainer button {
    margin: 1rem;
}
.merge-button {
    margin: 1rem -1rem 1rem -1rem;
    display: flex;
    justify-content: flex-end;

}

.bulk-edit-teacher-school-container .bulk-edit-container-person {
       
    padding: 1rem;
    border-radius: 1rem;
    
    
}

.bulk-edit-option-card{
    border:1px solid #dddddd;
    border-radius: 1rem;
    padding: 1rem;
    margin: 1rem 0;
}
/* .bulk-edit-teacher-school-container h3 {
    text-decoration: underline;
}
.bulk-edit-teacher-school-button-container h3{
    margin: -1rem -1rem 1rem -1rem;
    padding: 1rem;
    border-radius: 1rem 1rem 0 0;
    text-decoration: underline;
    
} */