:root {
    
    --color-base: #1b65e4;
    --color-base: hsl(218, 79%, 50%);
    --color-base: #1b65e4;
    --color-base: hsl(225, 50%, 50%);

    --color-base-attention: hsl(0,50%, 50%);
    
    --color-color-1-h: 225;
    --color-color-1-s: 50%;
    --color-color-1-l: 50%;
    --link-color-hsl: hsl(var(--color-color-1-h), var(--color-color-1-s), var(--color-color-1-l));

    
    --color-mute: hsl(calc(var(--color-color-1-h)), calc(var(--color-color-1-s) - 50%), calc(var(--color-color-1-l) + 0%));
    --color-focus: hsl(calc(var(--color-color-1-h) + 45), calc(var(--color-color-1-s) + 25%), calc(var(--color-color-1-l) - 0%));
    /* --color-focus: hsl(calc(var(--color-color-1-h) - 45), calc(var(--color-color-1-s) + 0%), calc(var(--color-color-1-l) - 0%)); */
    --color-highlight: hsl(calc(var(--color-color-1-h) + 90), calc(var(--color-color-1-s) + 0%), calc(var(--color-color-1-l) - 0%));


    --color-base-attention: hsl(360, 100%, 50%);
    
    --color-color-1-att-h: 360;
    --color-color-1-att-s: 70%;
    --color-color-1-att-l: 50%;
    --link-color-hsl-att: hsl(var(--color-color-1-att-h), var(--color-color-1-att-s), var(--color-color-1-att-l));

    --color-danger: hsl(calc(var(--color-color-1-att-h) + 0 ), calc(var(--color-color-1-att-s) + 0%), calc(var(--color-color-1-att-l) + 0%));
    --color-warning: hsl(calc(var(--color-color-1-att-h) + 45 ), calc(var(--color-color-1-att-s) - 0%), calc(var(--color-color-1-att-l) - 0%));
    --color-pop: hsl(calc(var(--color-color-1-att-h) + 90 ), calc(var(--color-color-1-att-s) - 0%), calc(var(--color-color-1-att-l) - 0%));

  

    --color-branch-focus: #FFD700;
    --color-branch-mute: #aeb7b3;
    
    --color-branch-color-1: #990099;
    --link-color-cold: hsl(calc(var(color-branch-color-1) - 80), var(--link-color-s), var(--link-color-l));
    --color-branch-warning: #000000;
    --color-branch-danger: #000000;
    --color-branch-pop: #000000;

    --hover-brigtness:1.2;
    
}


.branch-contaier{
    margin:.5rem;
    margin-bottom: 2rem;
    border-radius: 4px;
    padding:.5rem;
}

.branch-contaier .group{
    margin: .5rem 2.5em;
    padding:.5rem;
    
    border:1px solid #AAAAAA;
    border-radius: 4px;
}

.branch-contaier .branch-options{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
    align-items: center;
    /* padding: 0 6rem; */
}
.branch-contaier .branch-options.buttons-5 {
    flex-direction: column;
}

.branch-contaier .branch-options.buttons-5 .branch-limb-btn{
    width: min(90vw, 400px);
}

.branch-contaier .branch-options .back-to-parent-btn{
    position: absolute;
    left:0;
    top:2px;
}

.branch-contaier .branch-options .branch-limb-btn{
    background-color: var(--color-base);
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    font-size: 1.25em;
    font-weight: bolder;
    color:#ffffff;
    font-weight: 700;
    margin-bottom: 1rem;
}
.branch-contaier .branch-options .branch-limb-btn:hover{
    box-shadow: 2px 2px 5px #aaaaaa;
}

.branch-contaier .branch-options .branch-limb-btn.type-option-highlight{
    background-color: var(--color-highlight);
                }
.branch-contaier .branch-options .branch-limb-btn.type-option-mute{
    background-color: var(--color-mute);
}
.branch-contaier .branch-options .branch-limb-btn.type-option-focus{
    background-color: var(--color-focus);
}
.branch-contaier .branch-options .branch-limb-btn.type-option-pop{
    
    background-color: var(--color-pop);
}
.branch-contaier .branch-options .branch-limb-btn.type-option-warning{
    background-color: var(--color-warning);
}
.branch-contaier .branch-options .branch-limb-btn.type-option-danger{
    background-color: var(--color-danger);
}

.branch-contaier .branch-options .branch-limb-btn:hover{
    box-shadow: 3px 3px 3px #aaaaaa;
    filter: brightness(var(--hover-brigtness));
}

.branch-contaier .back-to-parent-btn{

}

.branch-container-outside{
    position: fixed;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    /* padding: 1rem; */
    /* min-height: 200px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
}

@media screen and (max-height: 500px) {
    
.branch-container-outside{
    position: relative;
    /* margin: 1rem;
    padding:1rem; */
}
}