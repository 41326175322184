.text-muted     {color:#aaaaaa;}
.text-muted-2   {color:#7a7a7a;}
.text-muted-3   {color:#444444;}
.text-active    {color:#0dcc80;}
.text-active-2  {color:#86e5bf;}
.text-bold      {color:#80b3ff;}
.text-soft      {color:#557bb4;}
.text-strong    {color:#1a4d9a;}
.text-light     {color:#ffffff;}
.text-danger    {color:#9a1a1a;}

.fill-muted     {background-color:#aaaaaa;}
.fill-muted-2   {background-color:#7a7a7a;}
.fill-muted-3   {background-color:#444444;}
.fill-muted-4   {background-color:#dddddd;}
.fill-muted-5   {background-color:#eeeeee;}
.fill-active    {background-color:#0dcc80;}
.fill-active-2  {background-color:#86e5bf;}
.fill-active-light {background-color:#80ffcd;}
.fill-active-other {background-color: #08e18a;}
.fill-active-image    {background-color:#dddddd;}
.fill-active-text {background-color: azure; color: #444444;}
.fill-bold      {background-color:#80b3ff;}
.fill-bold-2      {background-color:#bfd9ff;}
.fill-soft      {background-color:#557bb4;}
.fill-strong    {background-color:#1a4d9a;}
.fill-warning-1 {background-color: #ffffe6;}
.fill-warning-2 {background-color: #ffff00;}
.fill-warning-3 {background-color: #ffd041;}
.fill-ping-1 {background-color: #e6f0ff;}
.fill-ping-2 {background-color: #1a4d9a;}
.fill-question-1 {background-color: #e6fff5;}
.fill-question-2 {background-color: #0dcc80;}
.fill-alert-1   {background-color: #ffe6e6;}
.fill-alert-2   {background-color: #cc0d0d;}
.fill-danger {background-color:#9a1a1a}

.border-muted   {border-radius:3px; border: solid 1px #aaaaaa;}
.border-muted-2   {border-radius:3px; border: solid 1px #7a7a7a;}
.border-muted-3   {border-radius:3px; border: solid 1px #444444;}
.border-active-2  {border-radius:3px; border: solid 1px #86e5bf;}
.border-active  {border-radius:3px; border: solid 1px #0dcc80;}
.border-bold    {border-radius:3px; border: solid 1px #80b3ff;}
.border-soft    {border-radius:3px; border: solid 1px #557bb4;}
.border-strong  {border-radius:3px; border: solid 1px #1a4d9a;}
