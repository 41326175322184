.project-view{
    margin:.5em 0;
    border:solid 1px #aaaaaa;
    border-radius: 4px;
    padding:.5em 1em;
}

.project-view .header {
    font-weight: bolder;
}

.project-view .header .click{
cursor: pointer;
text-decoration: underline;
color:#0377fc;
}

.project-view .title{
    font-size: .8em;

}
.project-view .school{
    font-size: .85em;
}
.project-view .person{
    margin:0 1em .1em 0;
    background-color: #eee;
    padding:.2em 1em;
    border-radius: 3px;
    font-size: .75em;
}

.search .inputs{
    display:flex;
    justify-content: flex-end;
    align-items: center;
}

.search .inputs .form-control{
    max-width: 50%;
    margin-left:1em;
}

.displayandsafety-container .person{
    margin:0 1em .1em 0;
    background-color: #eee;
    padding:.2em 1em;
    border-radius: 3px;
    font-size: .75em;
}

.displayandsafety-container{
    margin:0 1em;
}

.input-review .content{
    white-space: pre-line;
    border:solid 1px #ccc;
    background-color:#fafafa;
    padding:1em;
    border-radius: 4px;
}

.button{
    background-color:#555;
    color:#eee;
    padding:.3em 1em;
    border-radius: 3px;
    cursor: pointer;
    transition: ease-in-out 2s;
   
}

.button:hover{
    transition: ease-in-out 300ms;
    box-shadow: 2px 2px 5px #4e4e4e;
}

.biliteracy-container{
    margin:0 1em;
    display: flex;
    justify-content: center;
}

.biliteracy-panel{
    padding:1em;
    border-radius: 4px;
    border:1px solid #aaaaaa;
    min-width: 300px;
}

.biliteracy-panel .instructions{
    margin-top:2em;
    border:1px solid #f1f1f1;
    padding:1em;
    border-radius: 4px;
}

.biliteracy-panel .category{
    display:flex;
    justify-content: space-between;
    padding:1em;
    border:1px solid #aaa;    
    border-radius: 4px;
    margin-bottom: 2em;
    min-width: 50vw;
}