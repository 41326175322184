@font-face {
    font-family: 'test-family';
    src: url('../fonts/BRUSHSCI.TTF');
}

.pdf-viewer {
    width: 800px !important;
    height: 1150px !important;
    border: 1px solid #aaaaaa;
}

.canvas-container {
    width: 800px !important;
    height: 1150px !important;
    margin: 16px;
}