.floor-planner{
    padding:0 1em;
}

.floor-planner .canvas-container{
    background-color: #aaa;
    padding: 0;
    margin:0;
    /* padding:.75em; */
    width:100% !important;
    height:calc(100vh - 400px) !important;
    overflow: auto;
}

.floor-planner .canvas-container #main-board{
    background-color: #949494;
    width:100%;
}

.floor-planner option.placed{
    background-color: #aaa;
    color:#ffffff;
}

.floor-planner .table-assignments{
    border:1px solid #aaaaaa;
    padding: 1em;
    border-radius: 4px;
}