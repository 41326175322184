.zpdf{
    display: flex;
    justify-content: center;
}

.zpdf-desk {
    display: flex;
    flex-direction: column;
    background-color: #bbbbbb;
    padding:1em;
}

.zpdf-desk .zpdf-container{

}

.zpdf-desk .zpdf-tool-bar{
    display: flex;
    justify-content: space-between;
}
.zpdf-desk .zpdf-tool-bar.top{
    margin-top: -.25rem;
    margin-bottom: .5rem;
}
.zpdf-viewer-canvas{
    box-shadow: 2px 2px 8px #444;
}
.zpdf-page-button{
    width:2em;
}
.zpdf-viewer{
    position: relative;
}
.zpdf-page{
    position: relative;
}
.zpdf-input{
    position: absolute;
    background-color:rgba(205, 234, 250, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.zpdf-input.setup-mode{
    border: 1px solid goldenrod;
    cursor: pointer;
}

.zpdf-input.setup-mode:hover{
    box-shadow: 2px 2px 5px goldenrod;
}

.zpdf-input.z-signature{
    cursor: pointer;
    /* padding:.1em .5em; */
    background-color:rgb(250, 205, 242);
}

.zpdf-input.z-signature.owner-can{
    background: linear-gradient(.75turn, rgb(250, 205, 242),rgba(205, 234, 250) );
}

.zpdf-input input, .zpdf-input textarea{
    width: 100%;
    height: 100%;
    position: absolute;    
}
.zpdf-input textarea{
    position: absolute;
    top:1px;
    bottom:1px;
}

.wizard-question-container{
padding:1em .3em;
height: calc(100vh - 600px);
overflow: auto;
border:1px solid #aaa;

}

.qizard-question-tile{
    display:flex;
    justify-content: space-between;
    padding:.5em;
    border:1px solid #aaa;
    border-radius: 4px;
    margin-bottom: 1em;
    font-size: 1.2em;
}

.form-manager-field-list{
    max-height: calc(100vh - 600px);
    overflow: auto;
    border:1px solid #aaa;
    margin: 1em 0;
    padding: .5em;

}

.field-maper .field-to-map{
    padding:.4rem;
    margin:.25rem;
    border: 1px solid #aaaaaa;
}
.field-maper .field-to-map:nth-child(even){
    background-color: #bbbbbb;
}

.field-maper .field-to-map.map{
    border-right: 10px solid #637562;
}