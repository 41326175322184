
.loading-wrapper{
	position: fixed;
	top:0;
	right:0;
	left:0;
	bottom:0;
    background-color: rgba(50, 50, 50, .456);
    z-index: 99900;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.wrapper{
	width:200px;
	height: 200px;
	position: relative;	
    /* border: 1px solid brown; */
}
.circle{
    width:40px;
    height:40px;
    position: absolute;
    border-radius: 50%;
    background-color: #ffffff;
    left:10px;
    /* transform-origin: 50%; */
    animation: circle .5s alternate infinite ease;
}

@keyframes circle{
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
}
.circle:nth-child(2){
    left:80px;
    animation-delay: .2s;
}
.circle:nth-child(3){
    left:auto;
    right:10px;
    animation-delay: .3s;
}
.shadow{
    width:40px;
    height:8px;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    position: absolute;
    top:62px;
    transform-origin: 50%;
    z-index: -1;
    left:15%;
    filter: blur(1px);
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow{
    0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
}
.shadow:nth-child(4){
    left: 45%;
    animation-delay: .2s
}
.shadow:nth-child(5){
    left:auto;
    right:0;
    animation-delay: .3s;
}
.wrapper .loading-text{
    position: absolute;
    top:50px;
    /* font-family: 'Lato'; */
    font-size: 4rem;
    /* letter-spacing: 16px; */
    color: #fff;    
    text-align: center;
}

.loading-progress{
    background-color: #fff;
    font-size: 1.3rem;
    position: absolute;
    bottom: -2rem;
    width: 300px;
    padding: 1rem;
    border-radius: .3rem;
}