:root {
  --style-color-primary: #44525b;
  --style-color-text-on-primary: #ffffff;
  --style-color-text-on-primary-offset: #dddddd;

  --style-color-secondary: #eff5f9;
  --style-color-text-on-secondary: #ffffff;
  --style-color-text-on-secondary-offset: #dddddd;
  --style-color-secondary-darker: #a0a0a0;
  --style-color-white: #ffffff;
  --style-color-shadow: #cccccc;
  --style-color-base-dark: #555555;
}

.background-primary {
  background-color: var(--style-color-primary);
  color: var(--style-color-text-on-primary);
}

.background-secondary {
  background-color: var(--style-color-secondary);
  color: var(--style-color-text-on-secondary);
}

.x-style .page-header {
  background-color: var(--style-color-secondary);
  color: var(--style-color-text-on-secondary);
  margin: -1rem;
  padding: 1rem;
  margin-bottom: 1rem;
}



.x-style {
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.x-style .header-bar {
  background-color: var(--style-color-primary);
  color: var(--style-color-text-on-primary);
  flex-shrink: 0;
  /* height: min(max(25vh, 240px), 300px); */
  
}

.x-style .x-footer {
  margin-top: 4rem;
  min-height: 4rem;
  flex-grow: 1;
  flex-shrink: 0;
  background-color: var(--style-color-base-dark);
  color: var(--style-color-white);
  padding: 2rem;
  font-size: 1.1rem;
  z-index: -1;
}

.x-style .x-footer a {
  color: var(--style-color-white);
}

.x-style .app-logo {
  height: 50px;
}

.x-style .x-container {
  position: relative;
  margin: 0rem auto;
  padding: 1rem 0;
  width: min(calc(100vw - 4rem), 1280px);
}

.x-style .x-footer .x-container {
  position: relative;
  margin: 0rem auto;
  padding: 1rem 0;
  width: min(calc(100vw - 4rem), 1280px);
}


.x-style .header-bar .x-container {
  margin-top: -1rem;
  /* width: min(calc(100vw - 1rem), 1350px); */
}

.x-style .x-color-bar {
  position: relative;
}
.x-style .x-color-bar::after{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 10rem;
  background-color: var(--style-color-primary);
  z-index: -1;
}

.x-style .x-container.x-body .route-wrapper{
  background-color: var(--style-color-white);
  /* margin-top: -10vh; */
  padding: 1rem;
  position: relative;
  box-shadow: 1px 1px 3px var(--style-color-shadow);
  border-bottom: 30px solid var(--style-color-secondary);
  z-index: 0;
  min-height: 34vh;
  /* flex-shrink: 0;*/
  flex-grow: 1; 
}

.x-style.hide-layout-stuff .x-container{
  padding: 0;
}

.x-style.hide-layout-stuff .x-color-bar::after{
  display:none;
 } 
 .x-style.hide-layout-stuff .x-container.x-body .route-wrapper{
   box-shadow: none;
   border:none;
   margin: 0;
   padding:0;
 }

.x-style .x-logo img {
  max-height: 100px;
  max-width: 100px;
  border-radius: 5px;
  position: relative;
}

.x-style .x-logo {
  position: relative;
  width: 100px;
  height: 75px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.x-style .x-title{
  display: flex;
  justify-content: center;
  align-items:  center;
  
  text-align: center;
}

.x-style .x-title > * {
  margin-right: 2rem;
}


.x-style .navbar-default {
  background-color: transparent;
  border: none;
  color: var(--style-color-secondary);
}

.x-style .navbar-default .navbar-nav > li > a
,.x-style .navbar-default .navbar-nav .dropdown-menu > li > a {
  color: var(--style-color-text-on-primary);
}

.x-style .navbar-nav>li>.dropdown-menu {
  background-color: var(--style-color-primary);
}
.x-style .dropdown-menu>li>a:focus, .x-style .dropdown-menu>li>a:hover {
  background-color: var(--style-color-secondary);
  color: var(--style-color-text-on-secondary);
}

.x-style .navbar-default .navbar-nav > li > a:hover {
  color: var(--style-color-text-on-primary-offset);
}

.x-style .main-body-page {
}

.x-style .main-body-page.poster {
  padding: 1rem;
  text-align: center;
}

.x-style .poster img {
    
  max-height: calc(100vh - 10rem);

}

.x-style .main-body {
  margin:0;
}

.x-style .main-body .react-tabs__tab {
  bottom: 0;
}

.x-style .main-body .react-tabs__tab-list{
    background-color: var(--style-color-secondary);
    margin: -1rem -1rem 0 -1rem;
    padding: .5rem 1rem 0 1rem;
    font-size: 1.1rem;
    border:none;
    color: var(--style-color-text-on-secondary);
}

.x-style .main-body .react-tabs__tab--selected{
    /* border-color: var(--style-color-primary); */
    /* background-color: var(--style-color-primary); */
    border: none;
    background-color: var(--style-color-secondary);
    color: var(--style-color-primary);
    font-weight: bold;
    font-size: 1.1em;
}

.x-style .edit-content-btn {
  position:absolute;
  z-index: 10;
  right:.5rem;
  color: var(--style-color-text-on-secondary);
}



.x-style .main-body-wrapper{
  display: flex;
  align-items: top;
  justify-content: flex-start;

}

.x-style .side-bar-widgets{
  margin: 0 .75rem;
  flex-shrink: 0;
  width: 250px;
}

.widget-account .widget-profile-img {
  width:85px;
  height: 85px;
  border-radius: 99px;
  overflow: hidden;
  margin-right: 1rem;
  flex-shrink: 0;
}

.widget-account .widget-profile-img i{
  font-size: 6rem;
  padding: 0;
}

.widget-account .widget-profile-img img{

}
.widget{
  
  background-color: var(--style-color-white);
  padding: 1rem;
  box-shadow: 1px 1px 3px var(--style-color-shadow);
  border: 1px solid var(--style-color-secondary);
  margin-bottom: .75rem;
}
.widget h3{
  background-color: var(--style-color-secondary);
  color: var(--style-color-text-on-secondary);
  margin: -1rem -1rem 1rem -1rem !important;
  padding:.5rem
}

.widget-account{
  position: relative;
}
.modal-open .widget-account{
  position: static;
}


.widget-account .widget-header{
  display: flex;
}
.widget-account .widget-user-info {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
  /* position: relative; */
  flex-grow: 1;
}

.bottom-right{
  position: absolute;
  bottom: .5rem;
  right: 0rem;
  text-align: right;
}


.x-style .branch-entry-bar {
  margin: 0;
  /* margin-top: -0.5em; */
  margin-bottom: .75rem;
  padding: 1rem;
  border: 1px solid var(--style-color-secondary);
  border-left: 10px solid var(--style-color-secondary);
  text-align: center;


}

.x-style .branch-entry-bar:hover .entry-bar-text {
  text-decoration: underline;
}

.x-style .branch-entry-bar .entry-bar-text {
  font-size: 1.5em;
  font-weight: bold;
}

.x-style .branch-entry-bar{
  box-shadow: 3px 3px 10px #aaaaaa;
}

.x-style .branch-entry-bar:hover{
  box-shadow: 3px 3px 8px #000000;
}


.x-style .pbplus-logo {
  height: 30px;
  margin: -5px;
}

.x-style .pbplus-stick-man-banner {
  border-color: #8409F9;
  
}
.modal-open .x-style .pbplus-stick-man-banner{
  z-index: -1;
}

.showcase-project-3:nth-child(5n - 1) .showcase-tile-header{
  background-color: var(--style-color-set-5-1);
  color: var(--style-color-set-5-1-text);
}
.showcase-project-3:nth-child(5n - 2) .showcase-tile-header{
  background-color: var(--style-color-set-5-2);
  color: var(--style-color-set-5-2-text);
}
.showcase-project-3:nth-child(5n - 3) .showcase-tile-header{
  background-color: var(--style-color-set-5-3);
  color: var(--style-color-set-5-3-text);
}
.showcase-project-3:nth-child(5n - 4) .showcase-tile-header{
  background-color: var(--style-color-set-5-4);
  color: var(--style-color-set-5-4-text);
}
.showcase-project-3:nth-child(5n) .showcase-tile-header{
  background-color: var(--style-color-set-5-5);
  color: var(--style-color-set-5-5-text);
}



.showcase-project-3{
  /* border: 1px solid var(--style-color-secondary); */
  box-shadow: 1px 1px 4px var(--style-color-secondary);
  border-radius: 1rem;
  margin: 1rem;
  height: 400px;
  width: 320px;
  display: flex;
  flex-direction: column;
}


.showcase-project-3:hover {
  cursor: pointer;
  box-shadow: 2px 2px 8px var(--style-color-secondary);
}

.showcase-project-3 .showcase-tile-header{
   
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -1px -1px 0 -1px;  
  border-radius: 1rem 1rem 0 0;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.showcase-project-3 .showcase-tile-header h2 {
  text-overflow: ellipsis;
}

.showcase-project-3 .showcase-tile-header img{
  margin: 0;
  object-fit: contain;
  width: 322px;
  height: 270px;  
}

.showcase-project-3 .showcase-tile-body{
  padding: .5rem 1rem;
  margin: 0 -1px;
  background-color: #ffffff;
  height: 80px; 
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.showcase-project-3 .showcase-tile-body .between{
  display: flex;
  justify-content: space-between;
}

.showcase-project-3 .showcase-tile-body .title{
  font-weight: bolder;
  flex-grow: 1;
}

.showcase-project-3 .showcase-tile-footer{
  background-color: var(--style-color-primary);
  color:var(--style-color-text-on-primary);
  height: 50px;
  padding: 1rem;  
  flex-shrink: 0;
  border-radius: 0 0 1rem 1rem;
  margin: 0 -1px -1px -1px;
}

.showcase-project-3 .showcase-tile-footer .owners{
  margin: 0 .3rem;
  line-height: 1rem;
  text-align: left;
  display: block;
  text-overflow: ellipsis;
}

.showcase-project-3 .showcase-tile-header .sub-footer{
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  right:0;
  left:0;
  background-color: var(--style-color-secondary);
  color:var(--style-color-text-on-secondary);
  z-index: 10;
}

.showcase-project-3 .showcase-tile-footer .sub-footer i {
  color:var(--style-color-text-on-primary);
}

ul.important-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.important-links {
  font-size: 1.35rem;
}

.important-links li{
  /* list-style: none; */
  margin: 1rem .5rem;
}

.important-links a {
  color: var(--style-color-primary);
  text-decoration: none;
}
.schedule-widget-list h6{
  margin-bottom: 0;
  margin-top:1rem;
}
.schedule-widget-list ul{
  list-style: none;
  padding: 0;
  margin: 0;
}


@media (max-width: 1235px){
  .side-bar-widgets.left-side{
    order: 3;
    width: 100%;
    margin: 0;
  }
  .side-bar-widgets.right-side {
    order:2;
    margin: 0;
    margin-left: .75rem;
  }

  .side-bar-widgets.left-side .widget{
    margin: .75rem;
  }

  .main-body-wrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 975px){
  .main-body-wrapper .side-bar-widgets {
    width: 100%;
    display: block;
    margin: 0;
  }

  .x-style .side-bar-widgets .widget{
    margin: 1rem 0;
  }
}

@media (max-width:770px){
  .x-style .x-title{
    display: none;
  }
}