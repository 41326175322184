#custom-questions {
    display: flex;
    padding: 20px;
}

#custom-questions-menu {
    padding-right: 30px
}

#custom-questions-menu ul {
    list-style-type: none;
    padding-left: 0;
    padding-top: .5em;
    display: grid;
    grid-auto-rows: minmax(40px, auto);
    grid-row-gap: .5em;
    max-height: 800px;
    overflow-y: auto;
}

#custom-questions-menu .selected {
    border-color: rgb(1, 56, 76);
    border-style: dashed;
}

#custom-questions-menu ul button {
    width: 100%;
    height: 100%;
    text-align: left;
}

#custom-question-editor {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 15px;
}

#custom-question-editor header {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
}

#custom-question-editor footer {
    grid-column-start: 2;
}