
.border-soft {
  border: solid 1px #AAA;
}



.div-button {
  cursor: pointer;
  color: #888;
}

  .div-button:hover {
      color: #000;
  }

h1, h2, h3 {
  margin-top: 4px;
  margin-bottom: 2px;
}

.round-container {
  margin: 1rem -.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
}

.judging-round {
  margin: .7rem;
  display: block;
  width: 350px;
  height: 250px;
  position: relative;
}

.judging-round:hover {
  transform: scale(1.05);
}
.off-bottom-right {
  position: absolute;
  bottom: .5em;
  right: .5em;
}
.hover-shadow:hover {
  box-shadow: 0 0 5px 3px #DDD
}

  .judging-round .title {
      display: flex;
      justify-content: space-between;
  }

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.hover-success, .hover-primary, .hover-info, .hover-warning, .hover-danger, .hover-inverse, .hover-faded {
  cursor: pointer;
  opacity: .5;
}

  .hover-primary:hover {
      color: #5cb85c;
      opacity: 1;
  }

  .hover-success:hover {
      color: #5cb85c;
      opacity: 1;
  }

  .hover-info:hover {
      color: #5bc0de;
      opacity: 1;
  }

  .hover-warning:hover {
      color: #f0ad4e;
      opacity: 1;
  }

  .hover-danger:hover {
      color: #d9534f;
      opacity: 1;
  }

  .hover-inverse:hover {
      color: #292b2c;
      opacity: 1;
  }

  .hover-faded:hover {
      color: #f7f7f7;
      opacity: 1;
  }

.row-padding {
  padding: .35em;
  margin: .2em;
  border-radius: 3px;

}

.judge-assignment {
  padding: .25em;
  margin: .2em;
  border: solid 1px #dddddd;
  background-color: #f5f5f5;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
}

.judging-assignment-list{  
  display: flex;
  flex-wrap: wrap;
}

.label-checkbox {
  display: inline;
}

.badge {
  opacity: .6;
}
.badge:hover {
  opacity: 1;
}



.loading-cloud, #loading-cloud-screen {
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, .7);
  z-index: 99999;
  color: #ffffff;
}

.interview-time {
  font-size: smaller;
  opacity: .85;
}


.see-it {
  border: blue 3px solid;
  height: 500px;
  width: 500px;
  background-color: #5cb85c;
}

